jQuery(document).on("page.ready", function () {
  $ = jQuery;

  var isMobile = window.matchMedia("only screen and (max-width: 949px)").matches;

  /*****************************************************************
   * Blog Overview Filtering
   *****************************************************************/
  if ($("main.site-search").length) {
    var post_type;
    var category;
    // var category_name;
    // var cat_id;
    var category_ids = [];
    var searchValue;
    var page_count = 1;

    // Checks the Current Cat in category.php
    if (typeof currentCat !== "undefined") {
      if (!category) {
        category = currentCat;
      }
    }

    // Load more posts on Button Click
    $(".loadmore").click(function (e) {
      e.preventDefault();

      searchValue = $(".search-container form.search-form #blog-search-form").val();

      var button = $(this),
        data = {
          action: "loadmore_searchsite",
          query: loadmore_params.posts, // that's how we get params from wp_localize_script() function
          // page: loadmore_params.current_page,
          page: page_count,
          search: searchValue,
        };

      $.ajax({
        // you can also use $.post here
        url: loadmore_params.ajaxurl, // AJAX handler
        data: data,
        type: "POST",
        beforeSend: function (xhr) {
          $(".load-more.loading-dots").show();
          button.hide();
        },
        success: function (data) {
          if (data) {
            $(".post-grid").append(data);
            // loadmore_params.current_page++;
            page_count++;
            button.show();

            if (loadmore_params.current_page == loadmore_params.max_page) {
              button.hide(); // if last page, remove the button
            }
          } else {
            button.hide(); // if no data, remove the button as well
            $(".load-more-container").append('<p class="no-mas sub-title">No more posts.</p>');
          }

          $(".load-more.loading-dots").hide();
        },
      });
    });

    // For Search Form Field
    $(".search-form").submit(function (e) {
      e.preventDefault();
      post_type = $(".post-filter").attr("data-post_type");
      // category = $(".search-form #category").val();
      searchValue = $(this).find("#blog-search-form").val();
      page_count = 1;

      $(".search-form #search").val(searchValue);

      data = {
        action: "searchsite",
        // category_ids: category_ids.join(","),
        // post_type: post_type,
        search: searchValue,
      };

      $.ajax({
        url: loadmore_params.ajaxurl,
        data: data,
        type: "POST",
        beforeSend: function (xhr) {
          $(".load-cats.loading-dots").show();
        },
        success: function (data) {
          $(".loading-dots").hide();
          if (searchValue != "") {
            $("section.blog-search-section .title .subhead").text(
              `Search Results for "${searchValue}"`
            );
          }

          if (data) {
            $(".post-grid").html(data.posts);

            if (data.found_posts != null) {
              $("section.blog-search-section .title .h1").text(
                `There are ${data.found_posts} results matching your search`
              );
            } else {
              $("section.blog-search-section .title .h1").text(
                `We couldn't find a match for your search, please try again.`
              );
            }
          } else {
            $(".post-grid").html('<p style="text-align:center;">No posts found.</p>');
          }
        },
      });

      if ($(".no-mas").length) {
        $(".no-mas").remove();
      }
      $(".loadmore").show();

      // $("html, body").animate({scrollTop: $(".post-grid").offset().top}, 800);
    });

    // Mobile toggle / close filtering
    if (isMobile) {
      var toggleBtn = $(".mobile-control button.show-filter"),
        postFilterContain = $(".post-filter"),
        accordionContain = $(".post-filter dl.accordion");

      toggleBtn.click(function () {
        postFilterContain.stop().slideToggle(225);
        accordionContain.toggleClass("show");
        if ($(this).text() !== "Close") {
          $(this).text("Close");
        } else {
          $(this).text("Filter Search");
        }
      });
    }
  }
});
