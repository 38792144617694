jQuery(document).on("page.ready", function () {
  $ = jQuery;
  if ($(".accordion").length) {
    /*****************************************************************
     Accordions
    ******************************************************************/
    $(".accordion-tab button.h5").click(function (event) {
      var me = $(this),
        parent = $(this).parent().parent(".accordion-tab"),
        botOpen = $(parent).children("dd"),
        botHeight = $(botOpen).children(".content").outerHeight(true);

      if ($(parent).hasClass("open")) {
        $(parent).removeClass("open");
        $(me).attr("aria-expanded", "false");
        $(botOpen).attr("aria-hidden", "true");
        $(botOpen).css("height", 0);
      } else {
        $(".accordion-tab").each(function (index, el) {
          var botReset = $(this).children("dd");
          $(this).removeClass("open");
          $(this).children("dt").children("button").attr("aria-expanded", "false");
          $(botReset).attr("aria-hidden", "true");
          $(botReset).css("height", "0");
        });

        $(me).attr("aria-expanded", "true");
        $(parent).addClass("open");
        $(botOpen).attr("aria-hidden", "false");
        $(botOpen).css("height", botHeight);
      }
    });

    // In case they open a tab and then expand or shrink their screen
    $(window).resize(function (event) {
      if ($(".accordion-tab.open")) {
        var getBot = $(".accordion-tab.open").children("dd");
        newHeight = $(".accordion-tab.open").children("dd").children(".content").outerHeight(true);

        $(getBot).css("height", newHeight);
      }
    });
    /*****************************************************************
     END Accordions
    ******************************************************************/
  } // end check for page template
});
