jQuery(document).on('page.ready', function () {
  $ = jQuery;

  var isMobile = window.matchMedia(
    'only screen and (max-width: 949px)'
  ).matches;

  /*****************************************************************
   * Blog Overview Filtering
   *****************************************************************/
  if ($('main.blog-overview, main.programs-index').length) {
    var post_type;
    var category;
    var category_name;
    var cat_id;
    var category_ids = [];
    var searchValue;
    var page_count;

    function ajaxCall(datahere) {
      $.ajax({
        url: loadmore_params.ajaxurl,
        data: data,
        type: 'POST',
        beforeSend: function (xhr) {
          $('.load-cats.loading-dots').show();
        },
        success: function (data) {
          $('.loading-dots').hide();
          if (data) {
            $('.post-grid').html(data.posts);
          } else {
            $('.post-grid').html(
              '<p style="text-align:center;">No posts found.</p>'
            );
          }
        },
      });
    }

    $('.cat-name').on('click', function (e) {
      e.preventDefault();
      loadmore_params.current_page = 1;
      post_type = $(this).parents('.post-filter').attr('data-post_type');
      category = $(this).attr('data-subcategory-slug');
      category_name = $(this).attr('data-subcategory-name');
      cat_id = $(this).attr('data-subcategory-id');

      // Filter UI
      var listedFilters = $('.current-filters .filters-listed');
      listedFilters.append(
        '<span class="selected-subcat" data-selected-cat="' +
          category +
          '" data-selected-id="' +
          cat_id +
          '">' +
          category_name +
          ' <span class="close">(X)</span></span>'
      );
      $('.current-filters-contain').slideDown();
      $(this).addClass('active');

      category_ids.push(cat_id);
      $('.filters-listed').attr('data-selected-ids', category_ids);

      // Ajax to pull posts
      if ($('.no-mas').length) {
        $('.no-mas').remove();
      }

      if (category == '') {
        category = 'all';
      }

      data = {
        action: 'filterposts',
        post_type: post_type,
        category_ids: category_ids.join(','),
      };

      ajaxCall(data);
    });

    // Clear All Subcats
    $('.current-filters > button').click(function () {
      $('.filters-listed').children().remove();
      $('.sub-list .cat-name').removeClass('active');
      $('.current-filters-contain').slideUp();

      category_ids = [];
      $('.filters-listed').attr('data-selected-ids', '');

      data = {
        action: 'filterposts',
        category_ids: category_ids.join(','),
        post_type: post_type,
      };

      ajaxCall(data);
    });

    // Remove selected Sub cat from list of selected subcats
    $(document).on('click', '.selected-subcat .close', function () {
      var selectedCat = $(this).parent().attr('data-selected-cat');
      var selectedId = $(this).parent().attr('data-selected-id');
      $(this).parent().remove();

      // Sync Aside Active Sub Cat
      $('.sub-list .cat-name').each(function (index, el) {
        var asideSelectedCat = $(el).attr('data-subcategory-slug');

        if (asideSelectedCat === selectedCat) {
          $(el).removeClass('active');
        }
      });

      var index = category_ids.indexOf(selectedId);
      if (index > -1) {
        category_ids.splice(index, 1);
      }

      data = {
        action: 'filterposts',
        category_ids: category_ids.join(','),
        post_type: post_type,
      };

      // SlideToggleUp the current-filters-contain if only 1 subcat left in list
      if ($('.filters-listed').children().length === 0) {
        $('.current-filters-contain').slideUp();
        category_ids = [];
        $('.filters-listed').attr('data-selected-ids', '');

        data = {
          action: 'filterposts',
          category_ids: category_ids.join(','),
          post_type: post_type,
        };
      }

      ajaxCall(data);
    });

    // Checks the Current Cat in category.php
    if (typeof currentCat !== 'undefined') {
      if (!category) {
        category = currentCat;
      }
    }

    // Load more posts on Button Click
    $('.loadmore').click(function (e) {
      e.preventDefault();
      post_type = $('.post-filter').attr('data-post_type');
      searchValue = $(
        '.search-container form.search-form #blog-search-form'
      ).val();

      let postIds = $('.post-grid .post-card')
        .map(function () {
          return $(this).data('id');
        })
        .get(); // Assuming each post has a data-id attribute with the post ID

      //console.log(postIds);

      var button = $(this),
        data = {
          action: 'loadmore',
          query: loadmore_params.posts, // that's how we get params from wp_localize_script() function
          page: loadmore_params.current_page,
          search: searchValue,
          category_ids: category_ids.join(','),
          post_type: post_type,
          exclude_posts: postIds.join(','),
        };

      //console.log(data.exclude_posts);

      $.ajax({
        // you can also use $.post here
        url: loadmore_params.ajaxurl, // AJAX handler
        data: data,
        type: 'POST',
        beforeSend: function (xhr) {
          $('.load-more.loading-dots').show();
          button.hide();
        },
        success: function (data) {
          if (data) {
            $('.post-grid').append(data);
            loadmore_params.current_page++;
            button.show();

            if (loadmore_params.current_page == loadmore_params.max_page) {
              button.hide(); // if last page, remove the button
            }
          } else {
            button.hide(); // if no data, remove the button as well
            $('.load-more-container').append(
              '<p class="no-mas sub-title">No more posts.</p>'
            );
          }

          $('.load-more.loading-dots').hide();
        },
      });
    });

    // For Search Form Field
    $('.search-form').submit(function (e) {
      e.preventDefault();
      post_type = $('.post-filter').attr('data-post_type');
      // category = $(".search-form #category").val();
      searchValue = $(this).find('#blog-search-form').val();
      page_count = 1;

      $('.search-form #search').val(searchValue);

      data = {
        action: 'filterposts',
        category_ids: category_ids.join(','),
        post_type: post_type,
        search: searchValue,
      };

      $.ajax({
        url: loadmore_params.ajaxurl,
        data: data,
        type: 'POST',
        beforeSend: function (xhr) {
          $('.load-cats.loading-dots').show();
        },
        success: function (data) {
          $('.loading-dots').hide();
          if (searchValue != '') {
            $('section.blog-search-section .title .subhead').text(
              `Search Results for "${searchValue}"`
            );
          }

          if (data) {
            $('.post-grid').html(data.posts);

            if (data.found_posts != null) {
              $('section.blog-search-section .title .h1').text(
                `There are ${data.found_posts} results matching your search`
              );
            } else {
              $('section.blog-search-section .title .h1').text(
                `We couldn't find a match for your search, please try again.`
              );
            }
          } else {
            $('.post-grid').html(
              '<p style="text-align:center;">No posts found.</p>'
            );
          }
        },
      });

      if ($('.no-mas').length) {
        $('.no-mas').remove();
      }
      $('.loadmore').show();

      // $("html, body").animate({scrollTop: $(".post-grid").offset().top}, 800);
    });

    // Reset Loadmore Button when Category button clicked
    $(document).on(
      'click',
      '.cat-name, .selected-subcat .close, .current-filters > button',
      function () {
        $('.loadmore').show();

        // Ajax to pull posts
        if ($('.no-mas').length) {
          $('.no-mas').remove();
        }
      }
    );

    // Mobile toggle / close filtering
    if (isMobile) {
      var toggleBtn = $('.mobile-control button.show-filter'),
        postFilterContain = $('.post-filter'),
        accordionContain = $('.post-filter dl.accordion');

      toggleBtn.click(function () {
        postFilterContain.stop().slideToggle(225);
        accordionContain.toggleClass('show');
        if ($(this).text() !== 'Close') {
          $(this).text('Close');
        } else {
          $(this).text('Filter Search');
        }
      });
    }
  }
});
