// IE11 compatibility
var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
var pageReadyEvent = isIE11 ? "ready" : "swup:pageView";

/***
  Function called in swup:contentReplaced that fixes the AJAX issue of adding the .current-menu-item &
  aria-current="page" to menu <a href""> tags as you go from page to page
***/
/*function menuChange() {
  var pageUrl = window.location.pathname,
    allNavs = $( "#menu-main-nav-menu" ).children();

  $( allNavs ).each( function ( index, el ) {
    var menuUrl = $( this )
      .children()
      .get( 0 )[ "pathname" ],
      menuMarkup = $( this )
      .children()
      .get( 0 )[ "outerHTML" ],
      $me = $( this );

    if ( pageUrl == menuUrl ) {
      $( $me ).addClass( "current-menu-item" );

      var clean = menuMarkup.replace( '/">', '/" aria-current="page">' );
      $( $me ).html( clean );
    } else {
      $( $me ).removeClass( "current-menu-item" );

      var clean = menuMarkup.replace( ' aria-current="page"', "" );
      $( $me ).html( clean );
    }
  } );
}*/
/***
  END menuChange() function
***/

jQuery( document ).on( pageReadyEvent, function () {
  jQuery( document ).trigger( "page.ready" );
} );

jQuery( function ( $ ) {
  // We are using SWUP to take care of AJAX & History management 🙌
  // https://github.com/gmrchk/swup

  if ( isIE11 ) {
    return;
  }

  var swupOptions = {
    debugMode: true,
    containers: [ "#page-container" ],
    linkSelector: 'a[href^="${window.location.origin}"]:not([data-no-swup]):not([target="_blank"]), a[href^="/"]:not([data-no-swup]):not([target="_blank"]), a[href^="#"]:not([data-no-swup]):not([target="_blank"])',
    plugins: [
      new SwupGaPlugin(),
      new SwupPreloadPlugin(),
      new SwupBodyClassPlugin(),
      new SwupScrollPlugin( {
        animateScroll: false
      } )
      //new SwupScriptsPlugin()
    ]
  };

  if ( $( "#wpadminbar" ).length ) {
    // let's also switch out the WP Admin Bar if we're logged in
    swupOptions.containers.push( "#wpadminbar" );
  }

  window.theswup = new Swup( swupOptions );
} );

// Run any inline scripts (fixes Gravity Forms)
// ---
jQuery( document ).on( "swup:contentReplaced", function () {
  $( "main script" ).each( function () {
    eval( $( this ).html() );
  } );

  //menuChange();
} );