(function ($) {
  $(document).ready(function ($) {
    if ($("html").hasClass("is-animating")) {
      $("html").removeClass("is-animating");
      setTimeout(function () {}, 150);
    }

    // Your site's jQuery goes inside this .ready() function.
    /*****************************************************************
      Touch fix for devices to mimick hover effects
    ******************************************************************/
    $("*")
      .on("touchstart", function () {
        $(this).trigger("hover");
      })
      .on("touchend", function () {
        $(this).trigger("hover");
      });
  }); // end .ready()
})(jQuery);
// Anything above this line runs only one time on first page load

theScrollOutArgs = {
  // once: true,
  // cssProps: {
  //     visibleY: true
  // }
};

jQuery(document).on("page.ready", function () {
  // Anything below this line runs after every ajax page load
  $ = jQuery;
  var inHeight = window.innerHeight / 100;
  document.documentElement.style.setProperty("--vh", inHeight + "px");
  var isMobile = window.matchMedia(
    "only screen and (max-width: 949px)"
  ).matches;

  Splitting();
  // ParallaxScroll.init();
  //ScrollOut( theScrollOutArgs ).index();

  /*****************************************************************
    START – Opening & Closing of Global Menu
  ******************************************************************/
  $("#menu-btn").click(function (event) {
    if ($("body").hasClass("menu-open")) {
      $("body").removeClass("menu-open");
      $("#menu-btn").attr("aria-expanded", "false");
      $("#menu-panel").attr("aria-hidden", "true");
    } else {
      $("body").addClass("menu-open");
      $("#menu-btn").attr("aria-expanded", "true");
      $("#menu-panel").attr("aria-hidden", "false");
    }
  });
  $("#menu-close").click(function (event) {
    if ($("body").hasClass("menu-open")) {
      $("body").removeClass("menu-open");
      $("#menu-btn").attr("aria-expanded", "false");
      $("#menu-panel").attr("aria-hidden", "true");
    } else {
      $("body").addClass("menu-open");
      $("#menu-btn").attr("aria-expanded", "true");
      $("#menu-panel").attr("aria-hidden", "false");
    }
  });

  $(".service-tab button").click(function (event) {
    var me = $(this),
      parent = $(this).parent().parent(".service-tab"),
      botOpen = $(parent).children("dd"),
      botHeight = $(botOpen).children(".content").outerHeight(true);

    if ($(parent).hasClass("open")) {
      $(parent).removeClass("open");
      $(me).attr("aria-expanded", "false");
      $(botOpen).attr("aria-hidden", "true");
      if ($(window).width() > 950) {
      } else {
        $(botOpen).css("height", 0);
      }
    } else {
      $(".service-tab").each(function (index, el) {
        var botReset = $(this).children("dd");
        $(this).removeClass("open");
        $(this)
          .children("dt")
          .children("button")
          .attr("aria-expanded", "false");
        $(botReset).attr("aria-hidden", "true");

        if ($(window).width() > 950) {
        } else {
          $(botReset).css("height", "0");
        }
      });

      $(me).attr("aria-expanded", "true");
      $(parent).addClass("open");
      $(botOpen).attr("aria-hidden", "false");

      if ($(window).width() > 950) {
      } else {
        $(botOpen).css("height", botHeight);
      }
    }
  });

  // In case they open a tab and then expand or shrink their screen
  $(window).resize(function (event) {
    if ($(".service-tab.open")) {
      var getBot = $(".service-tab.open").children("dd");
      newHeight = $(".service-tab.open")
        .children("dd")
        .children(".content")
        .outerHeight(true);

      if ($(window).width() > 950) {
      } else {
        $(getBot).css("height", newHeight);
      }
    }
  });
  /*****************************************************************
    END – Opening & Closing of Global Menu
  ******************************************************************/

  /*****************************************************************
    For when we do a img src="example.svg" SVG use instead of the 
    <svg> html markup
  ******************************************************************/
  $("img.svg").each(function () {
    var $img = $(this);
    var imgID = $img.attr("id");
    var imgClass = $img.attr("class");
    var imgURL = $img.attr("src");
    var dataFill = $img.attr("data-fill-color");
    var dataStroke = $img.attr("data-stroke-color");
    $.get(
      imgURL,
      function (data) {
        // Get the SVG tag, ignore the rest
        var $svg = $(data).find("svg"),
          $svgElements = $(data).find("svg *");
        // Add replaced image's ID to the new SVG
        if (typeof imgID !== "undefined") {
          $svg = $svg.attr("id", imgID);
        }
        // Add replaced image's classes to the new SVG
        if (typeof imgClass !== "undefined") {
          $svg = $svg.attr("class", imgClass + " replaced-svg");
        }

        if ($img.parent(".svg-egg").length) {
          // console.log('Easter Egg.');
          $svgElements = $svgElements.removeAttr("stroke");
        }
        // Add Fill color of SVG
        if (typeof dataFill !== "undefined") {
          $svg = $svg.attr("fill", dataFill);
        }

        // Add Stroke color of SVG
        if (typeof dataStroke !== "undefined") {
          $svg = $svg.attr("stroke", dataStroke);
        }

        // Remove any invalid XML tags as per http://validator.w3.org
        $svg = $svg.removeAttr("xmlns:a");

        // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
        if (
          !$svg.attr("viewBox") &&
          $svg.attr("height") &&
          $svg.attr("width")
        ) {
          $svg.attr(
            "viewBox",
            "0 0 " + $svg.attr("height") + " " + $svg.attr("width")
          );
        }
        // Replace image with new SVG
        $img.replaceWith($svg);
      },
      "xml"
    );
  });

  /*****************************************************************
    Lazy Load Assets
  ******************************************************************/
  var images = document.querySelectorAll("img[data-msrc]");
  var config = {
    rootMargin: "5% 5% 5% 5%",
    threshold: 0,
  };

  var observer;

  if ("IntersectionObserver" in window) {
    observer = new IntersectionObserver(onChange, config);
    images.forEach(function (img) {
      return observer.observe(img);
    });
  } else {
    // console.log("%cIntersection Observers not supported", "color: red");
    // images.forEach((image) => loadImage(image));
    images.forEach(function (image) {
      return loadImage(image);
    });
  }

  var loadImage = function (image) {
    image.classList.add("fader-in");
    image.src = image.dataset.msrc;
  };

  function onChange(changes, observer) {
    changes.forEach(function (change) {
      if (change.intersectionRatio > 0) {
        // Stop watching and load the image
        loadImage(change.target);
        observer.unobserve(change.target);
      }
    });
  }

  /*****************************************************************
      Animation Library / Area.
      Scroll Magic + GSAP
    ******************************************************************/
  // init scroll magic
  var smcontroller = new ScrollMagic.Controller();

  $(".product-card").each(function () {
    var me = $(this);
    var showFromBot = new ScrollMagic.Scene({
      triggerElement: this,
      triggerHook: 0.65,
    })
      .on("start", function (event) {
        $(me).addClass("show");
      })
      //.addIndicators()
      .addTo(smcontroller);
  });

  $("#page-container").each(function () {
    var me = $(this);
    var toggleNav = new ScrollMagic.Scene({
      triggerElement: this,
      offset: 10,
      triggerHook: 0,
    })
      .on("start", function (event) {
        $(me).addClass("show");
      })
      .setClassToggle("body", "scrolled")
      //.addIndicators()
      .addTo(smcontroller);
  });

  $(".fade-in-up").each(function () {
    var me = $(this);
    var showFromBot = new ScrollMagic.Scene({
      triggerElement: this,
      triggerHook: 0.95,
    })
      .on("start", function (event) {
        $(me).addClass("show");
      })
      //.addIndicators()
      .addTo(smcontroller);
  });

  $(".fade-in").each(function () {
    var me = $(this);
    var showFromBot = new ScrollMagic.Scene({
      triggerElement: this,
      triggerHook: 0.95,
    })
      .on("start", function (event) {
        $(me).addClass("show");
      })
      //.addIndicators()
      .addTo(smcontroller);
  });

  $(".line-reveal").each(function () {
    var me = $(this);
    var lineReveal = new ScrollMagic.Scene({
      triggerElement: this,
      triggerHook: 1,
    })
      .on("start", function (event) {
        $(me).addClass("show");
      })
      //.addIndicators()
      .addTo(smcontroller);
  });

  $(".drawline").each(function () {
    var me = $(this);
    var lineReveal = new ScrollMagic.Scene({
      triggerElement: this,
      triggerHook: 0.85,
    })
      .on("start", function (event) {
        $(me).addClass("reveal");
      })
      //.addIndicators()
      .addTo(smcontroller);
  });

  /*****************************************************************
    Carousel for cards sliders
  ******************************************************************/
  var $slider = $(".cards-carousel.owl-carousel");
  $slider.each(function (index, el) {
    $(el).owlCarousel({
      lazyLoad: true,
      items: 1,
      nav: true,
      dots: false,
      navText: [
        '<svg xmlns="http://www.w3.org/2000/svg" width="10.852" height="19.703" viewBox="0 0 10.852 19.703"><g transform="translate(1.414 1.414)"><path d="M18,7.5l8.437,8.437L18,24.375" transform="translate(-18 -7.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></g></svg>',
        '<svg xmlns="http://www.w3.org/2000/svg" width="10.852" height="19.703" viewBox="0 0 10.852 19.703"><g transform="translate(1.414 1.414)"><path d="M18,7.5l8.437,8.437L18,24.375" transform="translate(-18 -7.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></g></svg>',
      ],
      responsive: {
        767: {
          items: 3,
        },
      },
    });
  });
  /*****************************************************************
    END – Carousel for cards sliders
  ******************************************************************/
});
